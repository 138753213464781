/* From Uiverse.io by Juanes200122 */ 
.cube-loader {
  position: relative;
    /* u can choose any size */
  width: 75px;
  height: 75px;
  transform-style: preserve-3d;
  transform: rotateX(-30deg);
  animation: animate 4s linear infinite;
}

@keyframes animate {
  0% {
    transform: rotateX(-30deg) rotateY(0);
  }

  100% {
    transform: rotateX(-30deg) rotateY(360deg);
  }
}

.cube-loader .cube-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
    /* top: 0;
    left: 0; */
  transform-style: preserve-3d;
}

.cube-loader .cube-wrapper .cube-span {
  position: absolute;
  width: 100%;
  height: 100%;
    /* top: 0;
    left: 0; */
    /* width 75px / 2 = 37.5px */
  transform: rotateY(calc(90deg * var(--i))) translateZ(37.5px);
  background: linear-gradient(to bottom,
            hsl(0, 0%, 0%) 0%,
            hsl(0, 0%, 0%) 5.5%,
            hsl(0, 0%, 0%) 12.1%,
            hsl(0, 0%, 100%) 100%,
            hsl(0, 0%, 0%) 27.9%,
            hsl(0, 0%, 0%) 36.6%,
            hsl(0, 0%, 0%) 45.6%,
            hsl(0, 0%, 100%) 100%,
            hsl(0, 0%, 0%) 63.4%,
            hsl(0, 0%, 0%) 71.7%,
            hsl(0, 0%, 0%) 79.4%,
            hsl(0, 0%, 100%) 100%,
            hsl(0, 0%, 0%) 100%,
            hsl(0, 0%, 0%) 100%,
            hsl(0, 0%, 0%) 100%,
            hsl(0, 0%, 100%) 100%);
}

.cube-top {
  position: absolute;
  width: 75px;
  height: 75px;
  background: hsl(330, 3.13%, 25.1%) 0%;
    /* width 75px / 2 = 37.5px */
  transform: rotateX(90deg) translateZ(37.5px);
  transform-style: preserve-3d;
}

.cube-top::before {
  content: '';
  position: absolute;
    /* u can choose any size */
  width: 75px;
  height: 75px;
  background: hsl(0, 0%, 0%) 19.6%;
  transform: translateZ(-90px);
  filter: blur(10px);
  box-shadow: 0 0 10px #323232,
        0 0 20px hsl(0, 0%, 100%) 19.6%,
        0 0 30px #323232,
        0 0 40px hsl(0, 0%, 100%) 19.6%;
}

